<template>
    <div class="modal-overlay" @click.self="handleCloseModal">
      <div class="relative w-full px-6">
        <div class="absolute top-7 right-14 z-10 p-1 bg-tp-yellow rounded-full flex items-center justify-center cursor-pointer">
            <img class="w-3 h-3" :src="closeIcon" alt="close-icon" @click="handleCloseModal">
        </div> 
        <div class="flex flex-col gap-8 justify-center border-4 border-tp-yellow bg-tp-black rounded-3xl pt-4 pb-6">
          <p class="text-tp-yellow font-bold text-3xl px-6 border-b border-tp-yellow pb-3">Confirmación</p>
          <div class="flex flex-col gap-4 px-6">
            <p class="text-tp-yellow text-base">¿Estás seguro de que quieres votar por la canción <span class="underline">{{ songName }}</span>?</p>
          </div>
          <div class="flex gap-6 px-6 justify-center">
            <button @click="emitFreeVote" class="bg-tp-yellow text-tp-black font-semibold py-2 w-20 rounded-full">Si</button>
            <button @click="handleCloseModal" class="bg-tp-yellow text-tp-black font-semibold py-2 w-20 rounded-full">No</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import closeIcon from '@/assets/images/black-close-icon.png';
  
  export default {
    name: 'ConfirmGeneralVote',
    props: {
      songName: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const handleCloseModal = () => {
        emit('onCloseModal');
      };
  
      const emitFreeVote = () => {
        emit('onConfirmVote');
      };
  
      return {
        closeIcon,
        handleCloseModal,
        emitFreeVote
      };
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 50% !important;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    max-width: 428px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  </style>
  