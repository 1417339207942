<template>
  <div class="flex items-center gap-2">
    <div class="flex items-center justify-between bg-tp-yellow rounded-full w-20 px-4 border-2 border-tp-black">
      <button class="text-tp-black font-bold" @click="decrement">-</button>
      <img class="h-10" :src="line" alt="">
      <button class="text-tp-black font-bold" @click="increment">+</button>
    </div>
    <div class="flex items-center justify-center rounded-full w-10 h-10 bg-tp-yellow border-2 border-tp-black font-bold text-tp-black">{{ count }}€</div>
  </div>
</template>

<script>
import line from '@/assets/images/line.png';
import { ref } from 'vue';

export default {
  name: 'Counter',
  setup() {
    const count = ref(0);

    const increment = () => {
      count.value++;
    };

    const decrement = () => {
      if (count.value > 0) {
        count.value--;
      }
    };
    
    return {
      line,
      count,
      increment,
      decrement
    }
  }
}
</script>

<style>
.counter {
  display: flex;
  align-items: center;
}

button {
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
}

.circle {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  font-size: 18px;
}
</style>
