<template>
  <div class="amount-slider bg-tp-black rounded-lg relative">
    <label for="amount-slider" class="text-tp-yellow font-semibold">Precio personalizado:</label>
    <div class="slider-container relative">
      <input
        id="amount-slider"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        v-model="value"
        @input="handleInput"
        class="slider"
      />
      <!-- Mostrar el valor a la derecha del slider -->
      <span class="slider-value">
        {{ value }}€
      </span>
    </div>
    <div class="relative flex justify-center items-center mt-6" @click="handleVoteClick">
      <div class="flex justify-center items-center bg-tp-yellow rounded-xl gap-2 p-3 w-36">
        <p class="text-tp-black font-semibold text-sm">+{{ votes }} votos</p>
        <img class="w-6 h-6" :src="rocket" alt="rocket">
      </div>
      <div class="p-1 absolute flex justify-center -bottom-5 left-1/2 transform -translate-x-1/2 text-tp-yellow text-xs bg-tp-black border-2 border-tp-yellow rounded-lg">({{ votes - baseVotes }} votos extra)</div>
    </div>
  </div>
</template>

<script>
import rocket from '@/assets/images/rocket-icon-black.png';

export default {
  name: 'AmountSlider',
  props: {
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 100
    },
    step: {
      type: Number,
      default: 1
    }
  },
  emits: ['update:value'],
  data() {
    return {
      value: this.min,
      rocket,
    };
  },
  computed: {
    votes() {
      return Math.floor(this.baseVotes + this.extraVotes + Math.floor(this.value / 5) * 3);
    },
    extraVotes() {
      if (this.value < 5) {
        return (this.value - 1);
      } else if (this.value < 10) {
        return (this.value - 1) * 1.5;
      } else {
        return (this.value - 1) * 2;
      }
    },
    baseVotes() {
      return this.value * 3;
    }
  },
  methods: {
    handleInput() {
      this.$emit('update:value', this.value);
    },
    handleVoteClick() {
      this.$emit('redirect', this.value); 
    }
  }
};
</script>

<style scoped>
.amount-slider {
  background-color: #0D0D0D; /* tp-black */
  border-radius: 0.5rem;
}

/* Estilos para el slider */
.slider-container {
  position: relative;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background-color: #313131; /* tp-placeholder-black */
  outline: none;
  border-radius: 4px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #F9F871; /* tp-yellow */
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #F9F871; /* tp-yellow */
  border-radius: 50%;
  cursor: pointer;
}

.slider-value {
  position: absolute;
  right: 0; /* Alineado a la derecha del slider */
  top: 24px; /* Ajusta la distancia desde el slider */
  color: #F9F871; /* tp-yellow */
  font-weight: bold;
  font-size: 0.9rem;
}
</style>
